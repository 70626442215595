jQuery(document).ready(function ($) {
  let currentPage = 1; // Tracks the current page
  let maxPages = 99; // Default max pages, updated dynamically
  let currentTagId = ""; // Tracks the current tag ID

  // Tag filter click handler
  $(".tags").on("click", function (e) {
    e.preventDefault();

    currentTagId = $(this).data("tag-id") || ""; // Update current tag ID
    currentPage = 1; // Reset pagination

    $(".tags").removeClass("active");
    $(this).addClass("active");

    // Fetch filtered posts and reset the grid
    fetchPosts(currentTagId, currentPage, true);
  });

  // Load more click handler
  $(".load-more").on("click", function (e) {
    e.preventDefault();

    // Prevent loading more if no more pages
    if (currentPage >= maxPages) {
      updateLoadMoreText("No more posts to load");
      return;
    }

    currentPage++; // Increment the page
    fetchPosts(currentTagId, currentPage, false);
  });

  function fetchPosts(tagId, page, reset) {
    $.ajax({
      url: afp_vars.afp_url,
      method: "POST",
      data: {
        action: "filter_posts",
        afp_nonce: afp_vars.afp_nonce,
        tag_id: tagId,
        paged: page,
      },
      success: function (response) {
        console.log(response);

        if (response.success) {
          // Handle reset or append
          if (reset) {
            $(".case-studies-wrapper .inner.grid").html(response.data.posts); // Replace content
          } else {
            $(".case-studies-wrapper .inner.grid").append(response.data.posts); // Append content
          }

          // Update max pages and button state
          maxPages = response.data.max_pages;

          if (currentPage >= maxPages) {
            updateLoadMoreText("No more posts to load");
            $(".load-more").addClass("disabled");
          } else {
            updateLoadMoreText("Load more");
            $(".load-more").removeClass("disabled");
          }
        } else {
          console.error(response.data.message);

          if (reset) {
            $(".case-studies-wrapper .inner.grid").html(
              "<p>No posts found.</p>"
            );
          }

          updateLoadMoreText("No more posts to load");
          $(".load-more").addClass("disabled");
        }
      },
      error: function (xhr, status, error) {
        console.error("AJAX Error: " + error);
        console.log(xhr.responseText);
      },
    });
  }

  function updateLoadMoreText(text) {
    $(".load-more")
      .contents()
      .filter(function () {
        return this.nodeType === Node.TEXT_NODE; // Select only the text node
      })
      .first()
      .replaceWith(text); // Replace the text
  }

  // Reset the "Load More" button when filters change
  $(".tags").on("click", function () {
    updateLoadMoreText("Load more");
    $(".load-more").removeClass("disabled");
  });
});
