$(document).ready(function () {
  function initialiseCarousel(container) {
    const numImages = container.children("img").length; // Adjust selector if images are inside other elements
    const containerWidth = container[0].scrollWidth;

    // Clone the container's content to create a seamless loop
    if (container.children(".cloned").length === 0) {
      container.append(container.html()).addClass("cloned"); // Prevent duplicate appending
    }

    let scrollAmount = 0;

    function scroll() {
      scrollAmount += 1; // Adjust the increment to change speed

      if (scrollAmount >= containerWidth) {
        scrollAmount = 0; // Reset to the start for seamless looping
      }
      container.scrollLeft(scrollAmount);
    }

    container.data("interval", setInterval(scroll, 20)); // Store interval for cleanup
  }

  function destroyCarousel(container) {
    clearInterval(container.data("interval"));
    container.scrollLeft(0);
    container.find(".cloned").remove(); // Remove clones when disabling carousel
  }

  function handleCarousel() {
    $(".carousel-container").each(function () {
      const container = $(this);
      const numImages = container.children("img").length;

      // Check if the carousel should be active
      if (numImages > 4 || window.matchMedia("(max-width: 900px)").matches) {
        if (!container.data("isCarousel")) {
          initialiseCarousel(container); // Initialise if not already a carousel
          container.data("isCarousel", true); // Mark as carousel
        }
      } else {
        if (container.data("isCarousel")) {
          destroyCarousel(container); // Destroy if it was previously a carousel
          container.data("isCarousel", false); // Mark as non-carousel
        }
      }
    });
  }

  handleCarousel(); // Run on page load
  $(window).on("resize", handleCarousel); // Run on window resize
});
