$(document).ready(function () {
  // Function to activate the accordion item and trigger animations
  function activateAccordionItem(index) {
    $(".item-container").hide(); // Hide all item-container elements

    // Show the item-container corresponding to the accordion item
    $('.item-container[data-index="' + index + '"]').fadeIn();

    // Remove active from all items but retain the completed class where needed
    $(".accordion-item").removeClass("active");

    // Mark the previously active item as completed
    var previousIndex =
      (index - 1 + $(".accordion-item").length) % $(".accordion-item").length;
    $('.accordion-item[data-index="' + previousIndex + '"]').addClass(
      "completed"
    );

    // Activate the current accordion item
    var activeItem = $('.accordion-item[data-index="' + index + '"]');
    activeItem.addClass("active");

    // Trigger the blue border animation
    var blueBorder = activeItem.find("#blue-border");
    blueBorder.find("::after").css("transform", "scaleY(1)"); // Expands the blue border after activation

    // Ensure that the current item is marked as completed after a set duration
    setTimeout(function () {
      if (activeItem.hasClass("active")) {
        activeItem.addClass("completed");
      }
    }, 20000); // Match the duration of the animation
  }

  // Set up the automatic cycling of accordion items
  var currentIndex = 0; // Start from the first accordion item

  function cycleAccordionItems() {
    // Total number of accordion items
    var totalItems = $(".accordion-item").length;

    // Activate the current accordion item
    activateAccordionItem(currentIndex);

    // Increment the index for the next item
    currentIndex = (currentIndex + 1) % totalItems; // Cycle back to the first item when reaching the end
  }

  // Set an interval to automatically cycle through the accordion items every 20 seconds
  var intervalId = setInterval(cycleAccordionItems, 20000); // Adjust interval as needed

  // Initially activate the first item
  cycleAccordionItems();

  // Add click event listener to manually activate accordion items
  $(".accordion-item").click(function (event) {
    // If the target of the click is a link (<a> tag), allow it to function as a normal link
    if ($(event.target).is("a")) {
      return; // Don't prevent the default behaviour
    }

    event.preventDefault(); // Prevent the page jump for non-link clicks

    var clickedIndex = $(this).data("index");

    // Clear the interval to stop automatic cycling while clicking
    clearInterval(intervalId);

    // Activate the clicked item
    activateAccordionItem(clickedIndex);

    // Restart the interval for automatic cycling after a delay
    setTimeout(function () {
      intervalId = setInterval(cycleAccordionItems, 20000); // Restart automatic cycling
    }, 20000); // Delay before restarting the cycle (matching the interval)
  });
});
