import Swiper from "swiper";
import { Autoplay, Navigation } from "swiper/modules";
Swiper.use([Autoplay, Navigation]);

// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css";

// Initialize Swiper with progressbar pagination
$(document).ready(function () {
  const swiperLarge = new Swiper(".swiper-post-container", {
    direction: "horizontal",
    slidesPerView: 1,
    spaceBetween: 32,
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next-unique-social",
      prevEl: ".swiper-button-prev-unique-social",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar", // Progressbar type
    },
    /*  autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    }, */
  });

  // Update pagination and add classes on slide change
  swiperLarge.on("slideChange", function () {
    const currentIndex = swiperLarge.activeIndex;
    const totalItems = swiperLarge.slides.length;

    // Remove active and completed classes from all pagination items
    $(".pagination").removeClass("active completed");

    // Add active class to the current pagination item
    $($(".pagination")[currentIndex]).addClass("active");

    // Mark the previous pagination item as completed
    const prevIndex = (currentIndex - 1 + totalItems) % totalItems; // Wrap around to the end
    const nextIndex = (currentIndex + 1 + totalItems) % totalItems; // Wrap around to the end
    $($(".pagination")[prevIndex]).addClass("completed");
    $($(".pagination")[nextIndex]).addClass("completed");
  });

  // Initialize the first pagination item
  setTimeout(() => {
    $(".pagination").first().addClass("active");
  }, 295);

  setTimeout(() => {
    $(".pagination").first().removeClass("active").addClass("completed");
  }, 5200); // Main timeout

  /* -----------------------------------
---------------------------------------------------------------
--------------------------------------
------------------ NEXT SET OF SWIPER INITIALISED  */

  var testimonialSwiper = new Swiper(".testimonial-swiper", {
    spaceBetween: 30,
    effect: "fade",
    navigation: {
      nextEl: ".swiper-button-next-unique",
      prevEl: ".swiper-button-prev-unique",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  /* -----------------------------------
---------------------------------------------------------------
--------------------------------------
------------------ NEXT SET OF SWIPER INITIALISED  */

  $(document).ready(function () {
    // Initialize the main content Swiper
    const mainSwiper = new Swiper(".our-story-main-swiper", {
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next-unique",
        prevEl: ".swiper-button-prev-unique",
      },
      on: {
        init: function () {
          // Add 'active' class to the first items on initial load when Swiper is initialized
          updateActiveClass(this.activeIndex);
        },
        slideChange: function () {
          const currentIndex = this.activeIndex;
          updateActiveClass(currentIndex);
        },
      },
    });

    // Initialize the thumbnail Swiper
    const thumbSwiper = new Swiper(".our-story-thumb-swiper", {
      spaceBetween: 25,
      slidesPerView: 7,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      on: {
        slideChange: function () {
          scrollSlideIntoView(thumbSwiper);
        },
      },
    });

    // Initialize the timeline Swiper
    const timelineSwiper = new Swiper(".timeline-swiper", {
      spaceBetween: 0,
      slidesPerView: auto,
      centeredSlides: true,
      slideToClickedSlide: true,
      on: {
        slideChange: function () {
          scrollSlideIntoView(timelineSwiper);
        },
      },
    });

    // Helper function to scroll the active slide into view
    function scrollSlideIntoView(swiperInstance) {
      const currentIndex = swiperInstance.activeIndex;
      const currentSlide = swiperInstance.slides[currentIndex];

      if (currentSlide) {
        // Ensure the parent container's overflow isn't preventing visibility
        currentSlide.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }

    // Link the main Swiper with the thumb and timeline Swipers
    mainSwiper.controller.control = [thumbSwiper, timelineSwiper];
    thumbSwiper.controller.control = mainSwiper;
    timelineSwiper.controller.control = mainSwiper;

    // Helper function to update the active class on the timeline and thumbnail Swipers
    function updateActiveClass(index) {
      // Remove active class from all slides in both Swipers
      $(
        ".timeline-swiper .swiper-slide, .our-story-thumb-swiper .swiper-slide"
      ).removeClass("active");

      // Add active class to the current slide
      $(".timeline-swiper .swiper-slide").eq(index).addClass("active");
      $(".our-story-thumb-swiper .swiper-slide").eq(index).addClass("active");
    }

    // Ensure that the first slides get the 'active' class when Swipers are initialized
    mainSwiper.emit("init"); // Manually trigger init if needed
  });
});
